.Splash {
    $block: &;

    &-header {
        margin-bottom: ms($spacer-mega-ms);
    }

    &-body {
        margin: ms($spacer-mega-ms) 0;
    }

    &-actions {
        margin-bottom: ms($spacer-tera-ms);
    }
}
