.Signpost {
    $block: &;
    display: block;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    &--alignBaseline {
        align-items: baseline;
    }

    &--alignCenter {
        align-items: center;
    }

    &--alignTop {
        align-items: top;
    }

    &-icon {
        display: block;
        flex-shrink: 0;
        margin-right: ms($spacer-kilo-ms);
    }

    &-text {
        display: block;

        &--regular {
            @include text(0, g);
        }
    }

    &-link {
        text-decoration: none;
    }

    .Icon {
        width: ms(3);
        height: ms(3);
        margin-top: ms(-4);
    }

    .IconSocial {
        width: ms(5);
        height: ms(5);
    }
}
