.Video {
    $block: &;

    &-image {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 0 auto;
    }

    &-viewBox {
        position: relative;

        .is-loaded & {
            max-width: 100%;
            height: 0;
            padding-top: 56.25%;
            overflow: hidden;
        }
    }

    &-embed {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &-button {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: ms(8);
        height: ms(8);
        margin: auto;
        padding: 0;
        transition: $animation-duration-normal $animation-easing;
        border: 0;
        background: 0;
        appearance: none;
        cursor: pointer;

        &:hover {
            transform: scale(2);
        }
    }

    &-playIcon {
        width: 100%;
        height: 100%;

        * {
            fill: $color-background-interactive;
        }
    }

    &-text {
        margin-top: ms(2);

        &--showLoaded {
            display: none;

            .is-loaded & {
                display: block;
            }
        }

        &--hideLoaded {
            .is-loaded & {
                display: none;
            }
        }
    }
}

.Smart {
    display: block;
}

.Feature {
    display: none;
}
