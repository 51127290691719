.NavBar {
    $block: &;
    display: flex;

    &-navGroup {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    &-navItem {
        display: inline-block;
        position: relative;
    }

    &-navLink {
        @include text(1, e);
        display: block;
        padding: ms(-3) ms(0);
        transition: background-color $animation-duration-normal $animation-easing;
        border-radius: px($border-radius-round);
        color: $color-text-link;
        text-decoration: none;

        &.is-active,
        &:active {
            background-color: $color-background-interactive;

            &:hover,
            &:focus {
                background-color: $color-background-interactive;
                color: $color-text-brand;
            }
        }

        &:hover,
        &:focus {
            background-color: tint($color-background-interactive, 50%);
        }

        &.on-blue {
            color: $color-text-white;
            text-decoration: underline;

            &:hover,
            &:focus {
                color: $color-text-brand;
                text-decoration: none;
            }
        }
    }
}
