// THIS NEEDS TO BE REFACTORED INTO ATOMS AND MOLECULES

.ArrowBox {
    $block: &;
    position: relative;

    &:after {
        width: 0;
        height: 0;
        border-right: ms($spacer-kilo-ms) solid transparent;
        border-left: ms($spacer-kilo-ms) solid transparent;
        border-top: ms($spacer-kilo-ms) solid transparent;
        display: block;
        margin: 0 auto;
        content: "";
        position: absolute;
        right: 0;
        left: 0;
        bottom: -(ms($spacer-kilo-ms));
        z-index: 1;
        background: transparent;
    }

    &-promo-home {
        background-image:  url('/power_of_play/static/images/home.png');
        background-size: 200px;
        background-position: bottom right;
        background-repeat: no-repeat;
    }

    &-promo-contact {
        min-height: 300px;
        background-image:  url('/power_of_play/static/images/contact.png');
        background-size: 200px;
        background-position: bottom right;
        background-repeat: no-repeat;
    }

    &-promo-help {
        min-height: 300px;
        background-image:  url('/power_of_play/static/images/help.png');
        background-size: 200px;
        background-position: bottom right;
        background-repeat: no-repeat;
    }

    &-promo-homepostlogin {
        .HeroTitle-text {
            @include text(5, g);
        }
        .HeroTitle-image {
            display: block;
            text-align: center;
        }
        svg {
            max-width: 300px;
            margin: 0 auto;
        }
    }

    &-body {
        padding: ms($spacer-mega-ms);

        strong {
            font-weight: $font-weight-bold;
        }

        &--nopad {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .HeroTitle-description {
        display: block;
        margin-top: ms($spacer-kilo-ms);
    }

    .HeroTitle-sub {
        display: block;
        margin-bottom: ms($spacer-kilo-ms);
        color: $color-text-brand;
        @include text(2, g);
    }

    .Icon {
        width: ms(3);
        height: ms(3);
        margin-top: ms(-5);

        &--large {
            width: ms(6);
            height: ms(6);
            margin-top: ms(0);
        }
    }

    .IconGroup {
        .Icon {
            display: inline-block;
        }
    }

    .ButtonGroup {
        margin-top: ms($spacer-mega-ms);
    }

    .Testimonials {
        padding: 0 ms($spacer-mega-ms);
    }

    .Bubble {
        background-color: $color-background-brand;
        border-radius: ms(0);
        padding: ms($spacer-hecto-ms);
        margin-bottom: ms($spacer-kilo-ms);
        color: $color-text-white;
        white-space: normal;

        &:before {
            content: "\201C";
            @include text(6, g);
            display: block;
            margin-bottom: -(ms(0));
        }

        &:after {
            content: "\201D";
            @include text(6, g);
            display: block;
            text-align: right;
            margin-bottom: -(ms(0));
        }
    }

    .Avatar {
        margin-bottom: ms(2);

        &-image {
            margin-right: ms($spacer-kilo-ms);
            float: left;

            img {
                width: ms(6);
                height: ms(6);
                border-radius: ms(5);
            }
        }

        &-title {
            font-style: italic;
            white-space: normal;
        }

        &:after {
            width: 100%;
            display: block;
            clear: both;
            content: "";
        }
    }

    .Usp {
        &-image {
            margin: ms($spacer-mega-ms) 0 ms($spacer-kilo-ms) 0;
        }
        &-title {
            margin: ms($spacer-kilo-ms) 0;
            color: $color-text-brand;
            @include text(2, g);
            font-weight: $font-weight-bold;
        }
        &-description {
            margin: ms($spacer-kilo-ms) 0 ms($spacer-mega-ms) 0;
        }
        &-totals {
            margin-top: ms($spacer-mega-ms);

            .Course-count {
                float: left;
                @include text(6, a);
                color: $color-text-brand;
                margin-right: ms($spacer-kilo-ms);
            }

            .Course-count-description {
                font-weight: $font-weight-medium;
            }
        }
    }

    &-bg-brand {
        color: $color-text-white;
        background-color: $color-background-brand;

        &:after {
            background-color: transparent;
            border-top: ms($spacer-kilo-ms) solid $color-background-brand;
        }
    }

    &-bg-white {
        background-color: $color-background;

        &:after {
            background-color: transparent;
            border-top: ms($spacer-kilo-ms) solid $color-background;
        }
    }

    &-bg-alternating {


        &:nth-child(odd) {
            background-color: $color-background;

            &:after {
                background-color: transparent;
                border-top: ms($spacer-kilo-ms) solid $color-background;
            }
        }
        &:nth-child(even) {
             background-color: $color-background-alt;

            &:after {
                background-color: transparent;
                border-top: ms($spacer-kilo-ms) solid $color-background-alt
            }
        }


        .ButtonGroup {
            margin-bottom: ms($spacer-mega-ms);
        }
    }

    &-bg-alt {
        background-color: $color-background-alt;

        &:after {
            background-color: transparent;
            border-top: ms($spacer-kilo-ms) solid $color-background-alt;
        }

        .ButtonGroup {
            margin-bottom: ms($spacer-mega-ms);
        }
    }

    &-bg-black {
        background-color: $color-background-black;
        background-image:  url('/power_of_play/static/images/users.png');
        background-repeat: no-repeat;
        background-size: cover;

        .HeroTitle-text {
            margin: -(ms(-1)) 0;
        }

        &:after {
            background-color: transparent;
            border-top: ms($spacer-kilo-ms) solid $color-background-black;
        }
    }

    &-bg-no-arrow {
        &:after {
            width: 0;
            height: 0;
            display: none;
        }
    }
}
