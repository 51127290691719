.SiteHead {
    $block: &;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: space-between;
    padding: ms($spacer-kilo-ms) ms($spacer-mega-ms);
    background-color: $color-background-brand;
    overflow: hidden;

    &--bordered {
        border-bottom-width: px($border-width-hairline);
        border-style: solid;
        border-color: $color-border-brand;
    }

    &-actions,
    &-brand {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
