.SelectBox {
    $block: &;
    position: relative;
    width: 100%;
    padding-top: rem(58);
    border-width: px($border-width-hairline);
    border-style: solid;
    border-radius: px($border-radius-rounded);
    border-color: $color-border-brand;

    &--invalid {
        border-color: $color-border-danger;

        #{$block}-input {
            background-color: $color-background-danger;
            color: $color-text-white;
        }
    }

    &--valid {
        border-color: $color-border-success;

        #{$block}-input {
            background-color: $color-background-success;
            color: $color-text-white;
        }
    }

    &-input {
        @include text(2, e);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: background-color $animation-duration-normal $animation-easing;
        appearance: none;
        border: 0;
        background: none;
        text-indent: ms($spacer-mega-ms);

        &:active,
        &:focus {
            background-color: $color-background-interactive;
        }
    }

    &-icon {
        position: absolute;
        top: -(rem(8));
        right: ms($spacer-mega-ms);
        bottom: 0;
        width: ms(1);
        height: ms(1);
        margin: auto;
        transform: rotate(45deg);
        border-width: 0 rem(3) rem(3) 0;
        border-style: solid;
        border-color: $color-border-brand;
        z-index: $z-index-dropdown;
    }
}

.Select {
    margin-right: rem(5);
    padding-top: 0;
    width: 100%;
    height: rem(50);
    font-size: 1.05rem;
    color: #80aac3;
    border-width: px($border-width-hairline);
    border-color: $color-border-interactive;
    background-color: $color-background;
    transition: background-color $animation-duration-normal $animation-easing;
    white-space: nowrap;

    &:active,
    &:focus {
        background-color: $color-background-interactive;
    }

    &:checked {
        color: $color-border-brand;
    }

    &.SelectDateWidget {
        width: 30%;
    }
}
