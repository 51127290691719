/**
 * (optional) define here the style definitions which should be applied on the slider container
 * e.g. width including further controls like arrows etc.
 */
.slider {

    .frame {
        /**
         * (optional) wrapper width, specifies width of the slider frame.
         */
        width: 100%;

        position: relative;
        overflow: hidden;
        white-space: nowrap;

        .slides {
            //display: inline-block;
        }

        li {
            position: relative;
            display: inline-block;

            /**
             * (optional) if the content inside the slide element has a defined size.
             */
            width: 100%;
        }

        .prev, .next {
            width: ms(1);
            height: ms(2);
            position: absolute;
            top: 40%;
            margin-top: -25px;
            display: block;
            cursor: pointer;
        }

        .disabled {
            display: none;
        }

        .next {
            right: 0;
        }

        .prev {
            left: 0;
        }

        .next svg, .prev svg {
            width: ms(1);
        }
    }

}
