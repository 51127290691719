.ChoiceBox {
    $block: &;

    &-item {
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;

        & + & {
            margin-top: ms($spacer-kilo-ms);
        }
    }

    &-input {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;
        z-index: $z-index-deep;
    }

    &-choice {
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
            $scale: ms(7) - ms(-5);
            display: block;
            flex-shrink: 0;
            width: $scale;
            height: $scale;
            margin-right: ms(0);
            padding: ms(-5);
            transition: background-color $animation-duration-normal $animation-easing;
            border-width: px($border-width-hairline);
            border-style: solid;
            border-radius: px($border-radius-rounded);
            border-color: $color-border-interactive;
            background-clip: content-box;
            background-color: transparent;
            content: "";
        }

        #{$block}-input:checked + & {
            &::before {
                background-color: $color-background-brand;
            }
        }

        &--radio {
            &::before {
                border-radius: 100%;
            }
        }
    }
}

.Field-item li {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: ms($spacer-kilo-ms);

    & + & {
        margin-top: ms($spacer-kilo-ms);
    }
}

.RadioSelect {
    $block: &;
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    z-index: $z-index-deep;

    &-label {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-text-brand;

        &::before {
            display: block;
            flex-shrink: 0;
            width: rem(22);
            height: rem(22);
            margin-right: ms(0);
            padding: ms(-5);
            transition: background-color $animation-duration-normal $animation-easing;
            border-width: px($border-width-hairline);
            border-style: solid;
            border-radius: 100%;
            border-color: $color-border-interactive;
            background-clip: content-box;
            background-color: transparent;
            content: "";
        }
    }

    &:checked + #{$block}-label::before {
        background-color: $color-background-brand;
    }
}
