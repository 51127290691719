@mixin placeholder {
  &::placeholder {
    @content;
  }
}

%Input {
    $block: &;

    @include text(1, e);
    display: block;
    width: 100%;
    margin: ms(-2) 0;
    padding: ms(-2) ms(2);
    transition: background-color $animation-duration-normal $animation-easing, color $animation-duration-normal $animation-easing;
    border-width: px($border-width-hairline);
    border-style: solid;
    border-radius: px($border-radius-rounded);
    border-color: $color-border-interactive;
    outline: none;
    background-color: $color-background;
    color: $color-text-brand;

    @include placeholder {
        color: tint($color-text-brand, 50%);
    }

    &:focus {
        background-color: $color-background-interactive;
    }

    &--invalid {
        border-color: $color-background-danger;

        &:focus {
            background-color: $color-border-danger;
            color: tint($color-text-danger, 80%);
        }
    }

    &--valid {
        border-color: $color-border-success;

        &:focus {
            background-color: $color-background-success;
            color: tint($color-text-success, 80%);
        }
    }

    &:disabled,
    &:read-only {
        border-color: $color-border-disabled;
        background-color: $color-background-disabled;
        color: $color-text-disabled;
        cursor: not-allowed;
    }
}

.Input {
    @extend %Input;
}

.Text,
.Number,
.Email,
.URL,
.Password,
.Date,
.DateTime,
.Time {
    &Input {
        @extend %Input;
    }
}

.Textarea {
    @extend %Input;
}

.RadioSelect,
.CheckboxInput {
    &Input {
        display: inline-block;
        margin-right: ms($spacer-kilo-ms);
    }
}

input[type=checkbox] {
    appearance: none;
    width: rem(40);
    height: rem(40);
    padding: initial;
    border-color: $color-border-interactive;
    border-width: px($border-width-hairline);
    border-style: solid;
    border-radius: px($border-radius-rounded);
    transition: background-color $animation-duration-normal $animation-easing;

    &:checked {
        background-color: $color-background-brand;
    }
}
