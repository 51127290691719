.Form {
    $block: &;

    &-title {
        @include text(2, e);
        display: inline-block;
        width: 100%;
        margin-bottom: ms($spacer-hecto-ms);
        color: $color-text-brand;
    }

    &-text {
        @include text(0, g);
        margin-bottom: ms($spacer-kilo-ms);

        &--colorBrand {
            color: $color-text-brand;
        }

        &--styleBold {
            font-weight: $font-weight-bold;
        }
    }

    &-group {
        margin-top: ms($spacer-mega-ms);
    }

    &-item {
        padding-top: ms($spacer-hecto-ms);
    }

    &-field + &-field {
        //margin-top: ms($spacer-mega-ms);
    }

    &-item {
        .Field-item {
            margin-top: ms(-4);
        }
    }

    &-action {
        margin-top: ms($spacer-mega-ms);
    }

    &-message {
        margin: ms($spacer-giga-ms) 0;

        &Title {
            @include text(2, f);
            margin-bottom: ms($spacer-kilo-ms);
            color: $color-text-brand;
        }

        &Text {
            @include text(0, g);
        }
    }

    .ModelChoiceField-item {
        li + li {
            margin-top: ms($spacer-kilo-ms);
        }

        label {
            display: block;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-start;
        }
    }

    //This has to happen until we have more control with Django
    #id_user_details-terms_of_use {
        float: left;
    }

    .errorlist {
        margin-top: ms($spacer-kilo-ms);
        color: $color-background-danger;
    }

    .UserSessionModelChoiceField,
    .ChoiceField,
    .ModelChoiceField,
    .SingleAnswerChoiceField,
    .MultipleAnswerChoiceField {

        .Field-item {

            label {
                display: inline-block;
                margin-bottom: ms($spacer-kilo-ms) / 2;
                margin-top: ms($spacer-kilo-ms) / 2;

                input {
                    display: none;
                }

                input + span {
                    &:before {
                        $scale: ms(4) - ms(-2);
                        display: inline-block;
                        float: left;
                        flex-shrink: 0;
                        width: $scale;
                        height: $scale;
                        margin-right: ms(0);
                        margin-top: -(ms($spacer-kilo-ms) / 2);
                        padding: ms(-5);
                        transition: background-color $animation-duration-normal $animation-easing;
                        border-width: px($border-width-hairline);
                        border-style: solid;
                        border-radius: 100%;
                        border-color: $color-border-interactive;
                        background-clip: content-box;
                        background-color: transparent;
                        content: "";
                    }
                }

                input:checked + span {
                    &:before {
                        background-color: $color-background-brand;
                    }
                }

            }

        }

    }

    .MultipleAnswerChoiceField {
        .Field-item label {
            input + span {
                &:before {
                    border-radius: px($border-radius-rounded);
                }
            }
        }
    }
}

.Login {
    .Hidden {
        display: table;
    }
    .BooleanField {
        width: 100%;
        display: table;
        
        .Field-label {
            display: table-cell;
            vertical-align: middle;
        }
        .Field-item {
            display: table-cell;
            vertical-align: middle;
            text-align: right;
        }
        .Field-message {
            clear: both;
        }
    }
}
