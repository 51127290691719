.HeroTitle {
    $block: &;

    &-text {
        @include text(8, a);
        display: block;

        @each $key, $value in $brand-colors-map {
            &--color#{capitalize($key)} {
                color: $value;
            }
        }
    }

    &-divider {
        margin-top: ms($spacer-kilo-ms);
        margin-bottom: ms($spacer-kilo-ms);
    }
}
