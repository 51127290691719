.Answer {
    $block: &;
    display: block;

    &-title {
        display: block;
        color: $color-text-brand;
        margin-bottom: ms($spacer-kilo-ms);
    }

    &-entered {
        display: table;
        //padding-bottom: ms($spacer-kilo-ms) / 2;
    }

    &-feedback {
        display: table;
        margin-bottom: ms($spacer-kilo-ms);
        //padding-top: ms($spacer-kilo-ms) / 2;
        border-top: 2px solid $color-border-disabled;
    }

    &-text {
        width: 100%;
        display: table-cell;
        vertical-align: middle;

        strong {
            font-weight: $font-weight-medium;
        }
    }

    &-icon {
        width: 2em;
        display: table-cell;
        vertical-align: middle;
    }
}
