.Pagination {
    width: 100%;
    $block: &;
    display: block;
    display: table;
    text-align: center;
    list-style: none;
    padding: 0;
    margin-top: ms(1);
    padding-left: 0;

    &-increment,
    &-jump {
        display: table-cell;
    }

    &-increment {
        &--prev {
            margin-right: auto;
            display: block;
            background-image:  url('/power_of_play/static/images/arrow-left.png');
            background-repeat: no-repeat;
            background-position: left center;
            background-size: 1rem 1.75rem;
            padding-left: 1.5rem;
            text-align: left;
        }

        &--next {
            margin-left: auto;
            display: block;
            background-image:  url('/power_of_play/static/images/arrow-right.png');
            background-repeat: no-repeat;
            background-position: right center;
            background-size: 1rem 1.75rem;
            padding-right: 1.5rem;
            text-align: right;
        }

        &Link {
            @include text(2, e);
            color: $color-background-alt;
            line-height: 1.75rem;
            text-decoration: none!important;
            padding: 0;
            border: 0;
            background: transparent;

            &--active {
                color: $color-text-brand!important;
                text-decoration: none;
                cursor: pointer;
            }
        }
    }

    &-jump {
        & + & {
            margin-left: ms(-5);
        }

        &Link {
            display: block;
            width: 1em;
            height: 1em;
            border-radius: px($border-radius-round);
            background-color: $color-background-alt;
            text-decoration: none;
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;

            &--active {
                background-color: $color-background-brand;
                cursor: pointer;
            }
        }
    }
}
