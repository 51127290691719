.SessionListing {
    $block: &;
    display: block;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    &--faded {
        opacity: 0.5;
    }

    &-title {
        @include text(2, g);
        color: $color-text-brand;
    }

    &-list {
        &Item {
            margin-bottom: ms($spacer-mega-ms);
        }
    }


    &-text {
        width: 100%;
        @include text(1, e);
    }

    &-icon {
        width: 2em;
        align-items: flex-end;
    }

    .InlineLink {
        text-decoration: none;

        &:visited {
            color: $color-text-brand;
        }
    }

}
