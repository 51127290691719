.Attendance {
    $block: &;
    display: block;

    &-row {
        display: block;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    &-title,
    &-label,
    &-field {
        align-items: center;
    }

    &-title {
        width: 50%;
        color: $color-text;

        strong {
            @include text(2, c);
            color: $color-text-brand;
        }
    }

    &-label,
    &-field {
        width: 50%;
        margin-top: ms($spacer-kilo-ms);
        margin-bottom: ms($spacer-kilo-ms);
    }

    &-label {
        color: $color-text-brand;
    }
}
