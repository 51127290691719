// Call missing mixins
@mixin span($span) {
  // Set the flex basis and max-width of the element
  flex: 0 0 percentage($span / 12);
  max-width: percentage($span / 12);
}

.ProgressBar {
    $block: &;
    position: relative;
    width: 100%;
    height: rem(10);
    margin: ms($spacer-mega-ms) 0;
    background-color: $color-background-alt;
    overflow: hidden;

    &-steps {
        @include text(6, c);
        color: $color-text-brand;
    }

    &-realBar {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;
        appearance: none;

        @for $i from 1 to 100 {
            &[value="#{$i}"] + #{$block}-bar {
                width: unquote("#{$i}%");
            }
        }
    }

    &-bar {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        &--success {
            @include span(4);
            background: linear-gradient(to right, #005486, #20c2c6);
        }

        &--warning {
            @include span(8);
            background: linear-gradient(to right, #005486, #20c2c6, #ffd520);
        }

        &--danger {
            @include span(12);
            background: linear-gradient(to right, #005486, #20c2c6, #ffd520, #f4001b);
        }
    }

    &-content {
        @include text(2, e);
        color: $color-text-brand;
    }
}
