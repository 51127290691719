.Glyph {
    $block: &;
    display: block;
    width: 2em;
    height: 2em;
    padding: .5em;
    border-radius: 100%;
    background-color: $color-background-brand;
    overflow: hidden;

    &-item {
        display: block;
        width: 1em;
        height: 1em;
        margin: 0 auto;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: .5em;
        overflow: hidden;

        &--text {
            @include text(0, a);
            color: $color-text-white;
            font-size: 1em;
            letter-spacing: 0;
            text-align: center;
        }

        &--image,
        &--svg svg {
            display: block;
            width: auto;
            max-width: 1em;
            height: 100%;
            max-height: 1em;
            margin: 0 auto;
        }

        &--svg {
            position: relative;
            width: 1em;
            height: 1em;

            svg {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            * {
                fill: $color-text-white !important;
            }
        }
    }

    @each $key, $value in $brand-colors-map {
        &--color#{capitalize($key)} {
            background-color: $value;
        }
    }

    &--half {
        width: 1em;
        height: 1em;
    }

    &--lock {
        background-color: transparent;

        * {
            fill: $color-text !important;
        }
    }

    &--tick {
        background-color: transparent;

        * {
            fill: $color-text-success !important;
        }
    }

    &--cross {
        background-color: transparent;

        * {
            fill: $color-text-danger !important;
        }
    }
}
