.ImageGrid {
    $block: &;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    vertical-align: middle;

    &-item {
        display: inline-block;
        padding: 8px;
    }

    &-image {
        @include responsive-image;
    }
}
