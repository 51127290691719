.ContentList {
    $block: &;
    display: block;

    &-header {
        display: block;
    }

    &-body {
        padding: ms($spacer-mega-ms) 0;
    }

    &-item {
        & + & {
            margin-top: ms($spacer-mega-ms);
            padding-top: ms($spacer-mega-ms);
            border-top-width: px($border-width-hairline);
            border-style: solid;
            border-color: $color-border-brand;
        }
    }
}
