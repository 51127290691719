.SessionTitle {
    $block: &;
    display: block;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    &-small {
        @include text(-1, g);

        &--faded {
            opacity: 0.3;
        }
    }

    &-mid {
        @include text(2, g);
        color: $color-text-brand;

        &--strong {
            font-weight: $font-weight-regular;
        }
    }
}
