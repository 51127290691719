.SignpostList {
    $block: &;
    display: block;

    &--inline {
        margin-top: ms($spacer-mega-ms);
        margin-bottom: ms($spacer-mega-ms);
    }

    &-item {
        & + & {
            margin-top: ms($spacer-mega-ms);
        }
    }

    &--nomargin {
        .SignpostList-item + .SignpostList-item {
            margin-top: 0;
        }
    }
}
