.Breadcrumbs {
    width: 100%;
    $block: &;
    display: block;
    display: table;
    text-align: left;
    list-style: none;
    padding: 0;

    &-link {
        @include text(2, e);
        color: $color-text;
        line-height: 1.75rem;
        text-decoration: none!important;
        padding: 0;
        border: 0;
        background: transparent;
        opacity: 0.8;

        &--active {
            color: $color-text-interactive!important;
            text-decoration: none;
        }
    }

    &-divider {
        @include text(2, e);
        color: $color-text;
        line-height: 1.75rem;
        text-decoration: none!important;
        padding: 0 0.75rem;
        border: 0;
        background: transparent;
        opacity: 0.8;
    }
}
